export default function AboutPage() {
    return  (
      <>
    <h1>About</h1>
    <div className="aboutmain">
   
        <div className="exp">
          <li>
          <p>- Coding swift 2018 - 2024</p>
          <p>- Coding objective-c 2012 - 2024</p>
          <p>Lorem, ipsum dolor sit amet consectetur adipisicing elit. Autem expedita quaerat labore. Debitis minima eum hic, dignissimos</p>
          <p>Lorem, ipsum dolor sit amet consectetur adipisicing elit. Autem expedita quaerat labore. Debitis minima eum hic, dignissimos</p>
          <p>Lorem, ipsum dolor sit amet consectetur adipisicing elit. Autem expedita quaerat labore. Debitis minima eum hic, dignissimos</p>

          </li>
        
        </div>


        <div className="footer"></div>

    </div>
  </>
    );
  }
  