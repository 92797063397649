import { Link, useMatch, useResolvedPath } from "react-router-dom"

export default function Navbar() {
  return (
    <nav className="nav">
      <Link to="/home" className="site-title">
      <img src="saritwat_logo.png" class="img-cls"/>
      </Link>
      <ul>
      <CustomLink to="/home">Home</CustomLink>
        <CustomLink to="/project">Project</CustomLink>
        <CustomLink to="/about">About</CustomLink>
        {/* <CustomLink to="/contact">Contact</CustomLink> */}
      </ul>
    </nav>
  )
}

function CustomLink({ to, children, ...props }) {
  const resolvedPath = useResolvedPath(to)
  const isActive = useMatch({ path: resolvedPath.pathname, end: true })

  return (
    <li className={isActive ? "active" : ""}>
      <Link to={to} {...props}>
        {children}
      </Link>
    </li>
  )
}
