import React from 'react';
import '../home/blog.css'

export default function HomePage() {
  return (
    <>

      <div className="homeMain">
        <h1 className='h1Title'>We create websites and applications.<br></br>We are so glad you came to visit.<br></br>May this moment be the best for you.</h1>
        <HomeBanner />
        <ul className="ulBlog">
          <li><BlogGrid /></li>
          <li><BlogGrid /></li>
          <li><BlogGrid /></li>
        </ul>

      </div>

    </>
  );

}

function HomeBanner() {
  return (
    <h1>Banner1</h1>
  );
}

function MyButton() {
  return (
    <button>I'm a button</button>
  );
}

function BlogGrid() {
  return (
    <div className="blogmain">
      <div className="img">
        <img src="" alt=""></img>
      </div>


      <div className="content">
        <h3>Jan 20 2024</h3>
        <h1>Some title</h1>
        <p>
          Lorem, ipsum dolor sit amet consectetur adipisicing elit. Autem expedita quaerat labore. Debitis minima eum hic, dignissimos neque earum ea voluptas, itaque iure modi cupiditate nulla blanditiis? Autem, incidunt alias.
        </p>
      </div>


      <div className="footer"></div>

    </div>

  );
}

