import Navbar from "./components/Navbar"
import AboutPage from "./pages/about/AboutPage"
import ContactPage from "./pages/contact/ContactPage"
import HomePage from "./pages/home/HomePage"
import ProjectPage from "./pages/project/ProjectPage"
import { Route, Routes } from "react-router-dom"

function App() {
  return (
    <>
      <Navbar />
      <div className="container">
        <Routes>
        <Route index element={<HomePage />} />

          <Route path="/home" element={<HomePage />} />
          <Route path="/project" element={<ProjectPage />} />
          <Route path="/about" element={<AboutPage />} />
          {/* <Route path="/contact" element={<ContactPage />} /> */}
        </Routes>
      </div>
      
      <FooterView />
    </>
  )
}

function FooterView() {
  return (
    <div className="footerView">
      <h1>Copyright © Saritwat Anutrakoonpatiphan 2024</h1>
    </div>
  );
}



export default App
